import React from 'react'
import './types.scss'
import { ReactComponent as Doc } from '../../img/doc.svg'
import { ReactComponent as Docw } from '../../img/docw.svg'
import { ReactComponent as Car } from '../../img/types-car.svg'

function Types() {
    return (
        <section className="types">

            <div className="container">
                <h2 className="title titlew pc">Перевозка <br /> радиоактивных грузов </h2>
                <h2 className="title titlew mob">Перевозка <br /> радиоактивных <br /> грузов </h2>
                <div className="types-wrapper">
                    <div className="types__item">
                        <h3>Типы <br />
                            опасных грузов</h3>
                        <p>К данной категории относят любые материалы, вещества или отходы производства, которые могут нанести вред человеку и окружающей среде, привести к пожару, взрыву, гибели людей. Согласно международной классификации, выделяют 9 основных классов опасных грузов:</p>
                        <ul>
                            <li><Doc />Взрывчатые вещества</li>
                            <li><Doc />Газы (сжиженные, под давлением)</li>
                            <li><Doc />Легковоспламеняющиеся жидкости</li>
                            <li><Doc />Легковоспламеняющиеся твердые вещества, в том числе способные к самовозгоранию и воспламеняющиеся при контакте с водой</li>
                            <li><Doc />Окислители и органические пероксиды</li>
                            <li><Doc />Токсичные и инфекционные вещества</li>
                            <li><Doc />Коррозионные материалы</li>
                            <li><Doc />Прочие опасные вещества и изделия</li>
                            <li><Doc />Перевозка опасных грузов ЖД транспортом</li>
                        </ul>
                    </div>
                    <div className="types__item blue">
                        <Car className='car' />
                        <h3>Типы <br />
                            опасных грузов</h3>
                        <p>К данной категории относят любые материалы, вещества или отходы производства, которые могут нанести вред человеку и окружающей среде, привести к пожару, взрыву, гибели людей. Согласно международной классификации, выделяют 9 основных классов опасных грузов:</p>
                        <ul>
                            <li><Docw />Мы поможем получить соответствующие разрешения, оформим сопроводительные документы, согласуем транспортировку в МВД Москвы и других городов РФ; </li>
                            <li><Docw />Разработаем маршрут с учетом правил перевозки, дорожной обстановки и других факторов; </li>
                            <li><Docw />Мы поможем быстро пройти растаможку груза, подготовить необходимые документы, рассчитать размер пошлин и сборов и т. д.</li>
                            <li><Docw />Автомобильные перевозки осуществляются водителями с большим стажем и необходимыми допусками для работы с опасными грузами</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Types